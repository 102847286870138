<template>
  <div />
</template>

<script>
import Url from 'url-parse'
export default {
  components: {
  },
  data () {
    return {
    }
  },
  created () {
    this.messagePush()
  },
  methods: {
    messagePush () {
      this.api.base.messagePush({ taskId: this.$route.query.taskId }).then(result => {
        const data = result.data.data
        this.detail(data.Detail, result.data.value === 'daiban' ? 0 : 1)
      }).finally(() => {
      })
    },
    detail (row, taskViewMode) {
      let pathName = ''
      switch (row.procKey) {
        case 'project_apply':
          pathName = 'projectDetail'
          break
        case 'order_apply':
          pathName = 'orderDetail'
          break
        case 'contract_seal':
          pathName = 'contractDetail'
          break
        case 'enterprise_access':
          pathName = 'taskCompanyAccess'
          break
        case 'transport_apply':
          pathName = 'taskLogisticsTransport'
          break
        case 'credit_lmt_adjusting':
          pathName = 'taskQuotaChange'
          break
        case 'charge_apply':
          pathName = 'paymentCollectionDetail'
          break
        case 'payment_apply':
          pathName = 'paymentManagementDoDetail'
          break
        case 'contract_filed':
          pathName = 'contractAudit'
          break
        case 'warehousing_co_add':
          pathName = 'warehouseAudit'
          break
        case 'warehousing_co_alter':
          pathName = 'warehouseAuditChange'
          break
        case 'charge_confirm':
          pathName = 'paymentCollectionDoDetail'
          break
        case 'payment_confirm':
          pathName = 'paymentManagementDoDetail'
          break
        case 'stock_in_plan':
          pathName = 'receiptPlanApproval'
          break
        case 'discharging_cargo':
          pathName = 'releaseAudit'
          break
        case 'customs_broker_add':
          pathName = 'customsBrokerApproval'
          break
        case 'customs_broker_alter':
          pathName = 'customsBrokerApprovalChange'
          break
        default:
          pathName = ''
      }
      if (pathName) {
        const viewUrl = Url(row.viewUrl, true)
        // taskViewMode = 1 查看详情时才需要 procInstId
        this.$router.push({ name: pathName, query: { ...{ detailId: row.id, id: row.businessId, stockOutId: row.stockOutId, procInstId: Number(taskViewMode) === 1 ? row.procInstId : '', taskId: row.taskId }, ...this.utils.filterEmptyValue(viewUrl.query), ...{ taskViewMode: taskViewMode }, type: 'all', messageType: 'messagePush' } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.todo-list {
  .todo-item {
    font-size: 14px;
    margin-top: 20px;
    position: relative;
    p {
      width: 70%;
      display: inline-block;
      overflow: hidden;
      display:-webkit-box;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:1;
      margin-left: 20px;
      >span {
        //display: inline-block;
        height: 16px;
        line-height: 16px;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
    button {
      position: absolute;
      height: 30px;
      top: calc(50% - 15px);
      right: 15px;
    }
    .van-divider {
      margin-top: 5px;
    }

  }
}

</style>
